.btn-group-home {
  display: inline-block;
  padding: 15px;
  margin: 0 0.3em 0.3em 0;
  border: none;
  border-radius: 0.5em;
  box-sizing: border-box;
  text-decoration: none;
  background-color: white;
  box-shadow: inset 0 -0.6em 0 -0.3em rgba(0, 0, 0, 0.2);
}
.btn-group-home:hover {
  color: #eb0028;
}

.triangle-play {
  width: 0;
  height: 0;
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;

  border-left: 24px solid green;
}
