.parent {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 20% 20% 15% 20% 20%;
  grid-column-gap: 10px;
}

.text-top-left {
  grid-area: 1 / 1 / 3 / 2;
  margin-right: 10px;
}
.image-top-right {
  grid-area: 1 / 2 / 4 / 3;
}
.text-bottom-right {
  grid-area: 4 / 2 / 6 / 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 10px;
}
.image-bottom-left {
  grid-area: 3 / 1 / 6 / 2;
}

p {
  font-family: GothamBook;
}

#grid-events-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 1092px) {
  #grid-events-container {
    grid-template-columns: 1fr;
  }
}
