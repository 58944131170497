/* --------------- common slider CSS rules --------------- */
@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logos {
  overflow: hidden;
  padding: 60px 0;
  background: black;
  white-space: nowrap;
  position: relative;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  height: 100%;
  content: "";
  z-index: 2;
  width: 250px;
}

@media (max-width: 768px) {
  /* TABLET_WIDTH - check resources/global.json */
  .logos:before,
  .logos:after {
    width: 50px;
  }
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), black);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), black);
}

.logos:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide {
  display: inline-block;
  animation: 70s slide infinite linear;
}

.mobile_logo_img,
.desktop_logo_img {
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: scale-down;
}

/* --------------- mobile slider CSS rules --------------- */

.mobile_logo_div {
  height: 100px;
  width: 100px;
  background: rgb(255, 255, 255);
  display: inline-block;
  border-radius: 10%;
  margin: 0 20px;
}

.mobile_logo_img {
  border-radius: 10%;
}

/* --------------- desktop slider CSS rules --------------- */

.desktop_logo_img {
  filter: brightness(50%);
}

.desktop_logo_div {
  display: inline-block;
  text-align: center;
  height: 15vh;
  width: 15vw;
  overflow: hidden;
  margin: 0 1vw;
}
