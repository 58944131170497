.custom-card-container {
  aspect-ratio: 1;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-card {
  width: 100%;
  height: 100%;
  background-color: #22222200;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease;
}

.custom-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.custom-card-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  box-sizing: border-box;
  transition: opacity 0.3s ease;
}

.custom-card-heading {
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  color: #fff;
  transition: opacity 0.3s ease;
}

.custom-card-link {
  display: block;
}

.custom-card.hovered {
  transform: scale(1.05);
}

.custom-card.hovered .custom-card-caption,
.custom-card.hovered .custom-card-heading {
  opacity: 0;
}
