/*
 WHERE IS THE EVENT
*/

@media screen and (max-width: 768px) {
  .where-is-the-event > div:nth-child(2) {
    padding-bottom: 30px;
  }
}

.where-is-the-event > div:nth-child(1) {
  background-color: #191919;
  display: flex;
  padding: 50px;
  color: white;
  gap: 30px;
  font-size: 18px;
  flex-direction: column;
}

.where-is-the-event > div:nth-child(2) {
  background-color: #191919;
  display: grid;
  place-items: center;
}

.btn5-ed22 {
  display: inline-block;
  padding: 0.7em 1.4em;
  margin: 0 0.3em 0.3em 0;
  border: none;
  border-radius: 0.5em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "arial";
  text-transform: uppercase;
  font-weight: 800;
  color: white;
  background-color: #e62b1e;
  box-shadow: inset 0 -0.6em 0 -0.3em rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  cursor: pointer;
}
.btn5-ed22:hover {
  background: whitesmoke;
  color: #e62b1e;
}

/*
  THEME CARDS
*/

.theme-card-container {
  display: flex;
  flex-wrap: wrap;
}

.theme-card {
  background: linear-gradient(
    rgba(230, 43, 30, 0.8) 50%,
    rgba(227, 227, 227, 0.8)
  );
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.theme-card-image {
  position: absolute;
  width: 200px;
  top: 30px;
  right: 40px;
  z-index: 1;
  overflow: hidden;
  transform: scale(1.1);
}

.theme-card p {
  font-size: 18px;
  text-align: justify;
  max-width: 22ch;
  font-family: GothamBook;
  color: rgb(0, 0, 0);
}

.theme-card > p > strong {
  color: rgb(0, 0, 0);
}

@media screen and (max-width: 992px) {
  .theme-card p {
    max-width: 40ch;
  }
}

.header-ed2022 {
  background-image: url("../../components/images/Edizione2022.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/** SPEAKER CARD **/

.speaker-card {
  position: relative;
  overflow: hidden;
  flex: 0 0 300px;
}

.speaker-card-image {
  display: block;
  transition: all 0.3s ease;
}

@media screen and (min-width: 768px) {
  .speaker-card:hover .speaker-card-text {
    left: 0;
  }

  .speaker-card:hover .speaker-card-image {
    transform: scale(1.2);
  }
}

.speaker-card-text {
  position: absolute;
  bottom: 7%;
  left: -100%;
  background-color: #000;
  width: 60%;
  height: 15%;
  transition: all 0.3s ease;
  color: white;
  font-size: 23px;
  font-family: GothamBold;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

/**
* SPEAKER BIO POPUP
**/

.tag-speaker {
  width: fit-content;
  padding: 5px 15px;
  border-radius: 25px;
  background-color: #0c9deb;
  font-size: 15px;
}

/**
* SPINNER
**/

.spinner {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.spinner::before,
.spinner:after {
  content: "";
  position: absolute;
  border-radius: inherit;
}

.spinner:before {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(0deg, #e62b1e 0%, #822433 100%);
  animation: spin8932 1s infinite linear;
}

.spinner:after {
  width: 85%;
  height: 85%;
  background-color: black;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin8932 {
  to {
    transform: rotate(360deg);
  }
}
