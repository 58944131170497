/*2023 Community*/

.card-proC {
	display: grid;
	place-items: center;
}

.card-projectsC {
	width: 210px;
	height: 160px;
	border-radius: 10px;
	overflow: hidden;
	position: relative;
	justify-content: center;
	background-color: white;
	z-index: 9999;
}

.card-projectsC img {
	position: absolute;
	size: cover;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	object-fit: contain;
	transition: all 0.4s;
}

.btn1C {
	position: absolute;
	top: 15px;
	left: 15px;
	width: 30px;
	height: 30px;
	z-index: 100;
	appearance: none;
}

.btn1C::before {
	content: "i";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	font-weight: 700;
	display: flex;
	justify-content: center;
	border-radius: 5px;
	align-items: center;
	color: white;
	background: black;
	cursor: pointer;
	transition: all 0.4s;
}

.btn1C:checked::before {
	content: "X";
	position: absolute;
	background: white;
	color: black;
}

.overlayC {
	position: absolute;
	right: -80%;
	bottom: -80%;
	width: 210px;
	height: 160px;
	background: rgba(235, 0, 40, 0.9);
	border-radius: 5px;
	transition: all 0.4s;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.498);
	color: rgb(255, 255, 255);
	padding: 60px 10px 0 25px;
}

.overlayC p {
	font-size: 12px;
	font-family: GothamBook;
}

.btn1C:checked ~ .overlay {
	bottom: 0px;
	right: 0px;
	left: 0px;
	width: 210px;
	height: 160px;
}

/*.btn1:checked ~ img {
  transform: scale(2);
}*/

